var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.feeds,"page":_vm.page,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalPage},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_vm._v(" "+_vm._s(index+1)+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.updated_at))+" ")]}}])}),_c('v-dialog',{attrs:{"width":"800"},model:{value:(_vm.sendModal),callback:function ($$v) {_vm.sendModal=$$v},expression:"sendModal"}},[_c('v-card',{staticClass:"pa-6"},[_c('v-form',{ref:"form",staticClass:"sign__page__block",on:{"submit":function($event){$event.preventDefault();return _vm.callFunction()}}},[_c('h3',{staticClass:"mb-4"},[_vm._v("Добавить сообщение")]),_c('div',{staticClass:"item__column"},[_c('v-textarea',{attrs:{"filled":"","name":"input-7-4","label":"Сообщение"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_c('v-btn',{attrs:{"type":"submit","depressed":"","color":"primary"}},[_vm._v(" Сохранить изменения ")]),_c('v-btn',{attrs:{"depressed":"","color":"default"},on:{"click":function($event){_vm.sendModal=false}}},[_vm._v(" Отмена ")])],1)],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item__row item__ac"},[_c('h2',[_vm._v("Заявки")])])}]

export { render, staticRenderFns }